import { styled } from '../../stitches.config';
import { ButtonContainer } from './Button';

export const ButtonGroup = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '$small',
  alignItems: 'center',
  '@smallDevices': {
    flexDirection: 'column',
    [`& ${ButtonContainer}`]: {
      flexGrow: 1,
      width: '100%',
      boxSizing: 'border-box',
    },
  },
  defaultVariants: {
    align: 'left',
  },
  variants: {
    align: {
      left: {
        justifyContent: 'flex-start',
      },
      center: {
        justifyContent: 'center',
      },
      right: {
        justifyContent: 'flex-end',
      },
      between: {
        justifyContent: 'space-between',
      },
    },
  },
});
