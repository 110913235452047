import { FillAndCenter } from '../layout/FillAndCenter';
import { Stack } from '../layout/Stack';
import { Text } from '../typography/Text';
import { Spinner } from './Spinner';

export function Loading({
  message,
  error,
  errorMessage,
}: {
  message?: string;
  error: unknown;
  errorMessage: string;
}) {
  return (
    <FillAndCenter>
      {error === undefined || error === null ? (
        <Stack spacing="large" align="center">
          <Spinner />
          {message && (
            <Text weight="medium" size="large">
              {message}
            </Text>
          )}
        </Stack>
      ) : (
        <div>
          <b>{errorMessage}</b>
          {process.env.NODE_ENV == 'development' && (
            <pre>Error (Dev Only): {error.toString()}</pre>
          )}
        </div>
      )}
    </FillAndCenter>
  );
}
