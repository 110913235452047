import { captureException } from '@sentry/nextjs';
import { AxiosError } from 'axios';
import { toast } from 'sonner';

export const GENERIC_ERROR_MESSAGE = 'An unexpected error occurred. Please try again.';

export const extractErrorMessage = (error: any) => {
  if (!error.response) return '';
  if (typeof error.response.data?.detail === 'string') return ` ${error.response.data.detail}`;
  if (typeof error.response.data?.detail?.[0]?.msg === 'string') return ` ${error.response.data.detail[0].msg}`;
  return process.env.NODE_ENV === 'development' ? ` Error code (dev only): ${error.code}` : 'An unexpected error occurred. Please try again.';
};

export const handleAPIError = (error: unknown) => {
  if (error instanceof AxiosError && typeof error.response?.data?.errorMessage === 'string') {
    toast.error(error.response.data.errorMessage);
  } else {
    captureException(error);
    toast.error(GENERIC_ERROR_MESSAGE);
  }
};