import { styled } from '../../stitches.config';
import { Loading } from './Loading';

export function FullPageLoading({
  message,
  error,
  errorMessage,
}: {
  message?: string;
  error: unknown;
  errorMessage: string;
}) {
  return (
    <Container>
      <Loading message={message} error={error} errorMessage={errorMessage} />
    </Container>
  );
}

const Container = styled('div', {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
});
